import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../../components/layout"
import FooterCaption from "../../components/footer-caption"

import One from "../../../content/assets/door-hardware/1.jpg"
import Two from "../../../content/assets/door-hardware/2.jpg"
import Three from "../../../content/assets/door-hardware/3.jpg"
import Four from "../../../content/assets/door-hardware/4.jpg"
import Five from "../../../content/assets/door-hardware/5.jpg"
import Six from "../../../content/assets/door-hardware/6.jpg"
import Seven from "../../../content/assets/door-hardware/7.jpg"
import Eight from "../../../content/assets/door-hardware/8.jpg"
import Nine from "../../../content/assets/door-hardware/9.jpg"
import Ten from "../../../content/assets/door-hardware/10.jpg"
import Eleven from "../../../content/assets/door-hardware/11.jpg"
import Twelve from "../../../content/assets/door-hardware/12.jpg"
import Thirteen from "../../../content/assets/door-hardware/13.jpg"
import Fourteen from "../../../content/assets/door-hardware/14.jpg"
import Fifteen from "../../../content/assets/door-hardware/15.jpg"
import Sixteen from "../../../content/assets/door-hardware/16.jpg"
import Seventeen from "../../../content/assets/door-hardware/17.jpg"
import Eighteen from "../../../content/assets/door-hardware/18.jpg"
import Nineteen from "../../../content/assets/door-hardware/19.jpg"
import Twenty from "../../../content/assets/door-hardware/20.jpg"
import TwentyOne from "../../../content/assets/door-hardware/21.jpg"
import TwentyTwo from "../../../content/assets/door-hardware/22.jpg"
import TwentyThree from "../../../content/assets/door-hardware/23.jpg"
import TwentyFour from "../../../content/assets/door-hardware/24.jpg"
import TwentyFive from "../../../content/assets/door-hardware/25.jpg"
import TwentySix from "../../../content/assets/door-hardware/26.jpg"
import TwentySeven from "../../../content/assets/door-hardware/27.jpg"
import TwentyEight from "../../../content/assets/door-hardware/28.jpg"
import TwentyNine from "../../../content/assets/door-hardware/29.jpg"
import Thirty from "../../../content/assets/door-hardware/30.jpg"
import ThirtyOne from "../../../content/assets/door-hardware/31.jpg"
import ThirtyTwo from "../../../content/assets/door-hardware/32.jpg"
import ThirtyThree from "../../../content/assets/door-hardware/33.jpg"
import ThirtyFour from "../../../content/assets/door-hardware/34.jpg"
import ThirtyFive from "../../../content/assets/door-hardware/35.jpg"
import ThirtySix from "../../../content/assets/door-hardware/36.jpg"

const DoorAndWindow = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const photos = [
    {
      src: One,
      width: 1,
      height: 1.5,
      caption: `Colonial Style Thumb Latches. These can be made in many different sizes and styles including modern designs. Typical finish on these colonial latches is a baked on oil/wax.`,
    },
    {
      src: Two,
      width: 1,
      height: 1.5,
      caption: `Complete Thumb Latch Set. Thumb Latches can be made to be nailed and clinched or screwed to the door. Keepers can also be made to drive into, nail and clinch, or to screw to the door. Set includes handmade nails or modern screws, latch bar, and keepers designed for your desired fastening system.`,
    },
    {
      src: Three,
      width: 0.5,
      height: 1.5,
      caption: `Door Handle 13" Length, 1" thick handle. Can be made in any size.`,
    },
    {
      src: Four,
      width: 1.7,
      height: 1,
      caption: ``,
    },
    {
      src: Five,
      width: 2,
      height: 0.7,
      caption: ``,
    },
    {
      src: Six,
      width: 2,
      height: 0.8,
      caption: ``,
    },
    {
      src: Seven,
      width: 2,
      height: 1,
      caption: ``,
    },
    {
      src: Eight,
      width: 1,
      height: 1.7,
      caption: ``,
    },
    {
      src: Nine,
      width: 1.2,
      height: 1,
      caption: `Butterfly Hinge typically for cupboards, cabinets, small boxes etc. Typically attached with screws, but handmade nails clinched over on the inside can also be used. These are custom made so size and finish is up to the buyer. The finish can be partially shiny and partially black forged iron (like the one shown), shiny iron, black iron, or they can be painted colors.`,
    },
    {
      src: Ten,
      width: 1.1,
      height: 1,
      caption: `Decorative Hinge Straps 14 and 22 inches long with forged nails. Used when modern hinges are already installed. Gives a door a more historic or rustic look without the expense and installation labor of hand made hinges. As these are custom made, the size, style, and finish is up to the buyer.`,
    },
    {
      src: Eleven,
      width: 1.5,
      height: 0.5,
      caption: `Strap Hinges to fit on the outside of a door designed to open to the interior. These hinges are 1/4 inch by 1 1/2 inch by 30 inch and fit on 5/8 inch pintles. These can be made any size, finish, and with various decorative ends (arrowhead, bean, heart, spear, etc.). Hinges can be attached with hand forged nails (clinched over on the inside), screws, or bolts. Hinges shown have square holes for attachment with 1/4 inch carriage bolts. Matching nails, screws, or bolts can be provided.`,
    },
    {
      src: Twelve,
      width: 1.4,
      height: 1,
      caption: `This bolt is made for the bolt to go into a timber to hold the door shut, otherwise a second piece to hold the end of the bolt is necessary. This bolt is 12 inches long with a 5/8 inch round bolt, but they can be made any size. The round ball on the handle can also be made any shape such as hex or square. Many other styles are available.`,
    },
    {
      src: Thirteen,
      width: 1.4,
      height: 1,
      caption: `This bolt has the keeper and includes a 3/4" diameter bolt. It is painted semi-flat automotive paint for outdoor use.`,
    },
    {
      src: Fourteen,
      width: 1,
      height: 1,
      caption: `In 2002 Tom Babel purchased this barn in Pennsylvania. The barn was built in 1840 of hand hewn timbers. The barn was dismantled and moved to Wood River, Nebraska where it was reassembled and updated to be used as an office for the Babel Agency. OzBro Construction of Cairo, NE was the primary contractor.`,
    },
    {
      src: Fifteen,
      width: 1,
      height: 1,
      caption: `In 2002 Tom Babel purchased this barn in Pennsylvania. The barn was built in 1840 of hand hewn timbers. The barn was dismantled and moved to Wood River, Nebraska where it was reassembled and updated to be used as an office for the Babel Agency. OzBro Construction of Cairo, NE was the primary contractor.`,
    },
    {
      src: Sixteen,
      width: 1,
      height: 1,
      caption: `In 2002 Tom Babel purchased this barn in Pennsylvania. The barn was built in 1840 of hand hewn timbers. The barn was dismantled and moved to Wood River, Nebraska where it was reassembled and updated to be used as an office for the Babel Agency. OzBro Construction of Cairo, NE was the primary contractor.`,
    },
    {
      src: Seventeen,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: Eighteen,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: Nineteen,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: Twenty,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: TwentyOne,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: TwentyTwo,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: TwentyThree,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: TwentyFour,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: TwentyFive,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: TwentySix,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: TwentySeven,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: TwentyEight,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: TwentyNine,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: Thirty,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: ThirtyOne,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: ThirtyTwo,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: ThirtyThree,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: ThirtyFour,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: ThirtyFive,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
    {
      src: ThirtySix,
      width: 1,
      height: 1,
      caption: `Ironwork by Dick Nietfeld, Shady Grove Blacksmith Shop`,
    },
  ]

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <article>
        <h1>Door and Window Hardware</h1>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                components={{ FooterCaption }}
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Link
          style={{ display: "inline-block", marginTop: "24px" }}
          to="/gallery"
        >
          ← Back to Gallery
        </Link>
      </article>
    </Layout>
  )
}

export default DoorAndWindow
